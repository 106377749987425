import React from "react"
import cx from "classnames"
import toReact from "svelte-adapter/react"
import LoginComponent from "webkit/ui/LoginPrompt/index.svelte"
import Layout from "../../components/layout"
import styles from "./index.module.scss"

const Login = toReact(LoginComponent, {}, "div")

export default () => (
  <Layout classes={{ main: cx(styles.wrapper, "row hv-center") }}>
    <Login title="Welcome back" />
  </Layout>
)
